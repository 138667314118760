<template>
  <div class="ma-3 container--fluid">
    <v-row>
      <v-col xl="2" cols="2" class="pt-0 pl-0 pb-0">
        <v-card class="listMenu">
          <!-- <v-card-title class="pb-0">Menu</v-card-title> -->
          <ul>
            <v-divider></v-divider>
            <li><router-link to="/edit-site"><span><v-icon>{{ mdiArchive  }}</v-icon></span>  Sites</router-link></li>
            <v-divider></v-divider>
            <li> <span><v-icon>{{ mdiPlusBox  }}</v-icon></span>  Cadastro</li>
            <v-divider></v-divider>
            <li> <span><v-icon>{{ mdiDatabaseCog  }}</v-icon></span>  Banco de Dados</li>
            <v-divider></v-divider>
            <li> <span><v-icon>{{ mdiAlertOutline  }}</v-icon></span>  Bonjour Madamme</li>
          </ul>
        </v-card>
      </v-col>
      <v-col cols="7" xl="7" class="pb-0">

        <template>
          <v-card-title class="dispositivoTitle">Dispositivos</v-card-title>
            <v-simple-table class="elevation-2 dispositivoTable" >
              <template v-slot:default>
                <thead>
                  <tr style="background: rgb(103 124 155);">
                    <th v-for="(item, index) in headers" :key="index" :class="index < 2 ? 'text-center': ''">
                      {{item.text}}
                    </th>
                  </tr>
                </thead>
                <tbody class="tableBody">
                  <tr
                    v-for="item in sites"
                    :key="item.name"
                    @click="saber(item)"
                  >
                    <td class="text-center">{{ item._id }}</td>
                    <td class="text-center" >
                      <v-icon  :color=" item.commFlag == 1 ? 'green': 'red'">
                          {{ mdiCheckboxBlankCircle }}
                        </v-icon>
                    </td>
                    <td class="text-centerf">{{ item.title }}</td>
                    <td class="text-centerf">{{ item.city }}</td>
                    <td class="text-centerf">{{ item.state }}</td>
                    <td class="text-centerf">{{ item.sampleTime }}</td>
                    <td class="text-centerf">
                      <div>
                      {{ item.updateTime[1] | formatDate }}
                      </div>
                      <div>
                      {{ item.updateTime[1] | formatTime }}
                      </div>
                    </td>
                    <td class="text-centerf">{{ item.updateTime | formatDuration }}</td>


                  </tr>
                </tbody>
              </template>
            </v-simple-table>
        </template>

      </v-col>
      <v-col cols="3" xl="3" class="pb-0 cardAlerta">
        <alerts-table v-if="mockalert.length > 0" :alerts="mockalert" />
          <v-card v-else>
          <v-card-title class="headerAlert">Alertas</v-card-title>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import AlertsTable from "@/components/AlertsTable.vue";
import { mapGetters, mapActions } from "vuex";
import { mdiCheckboxBlankCircle, mdiArchive , mdiPlusBox , mdiDatabaseCog, mdiAlertOutline    } from "@mdi/js";


import { ROUTE_NAME } from "@/router";


  export default {
    components: {
      AlertsTable,
    },
    data () {
      return {
        items: [
        { header: 'Today' },
        {
          avatar: 'https://cdn.vuetifyjs.com/images/lists/1.jpg',
          title: 'Cadastrar',
        },
        { divider: true, inset: true },
        {
          avatar: 'https://cdn.vuetifyjs.com/images/lists/2.jpg',
          title: 'Adicionar novo Site',
        },
        { divider: true, inset: true },
        {
          avatar: 'https://cdn.vuetifyjs.com/images/lists/3.jpg',
          title: 'Oui oui',
        },
        { divider: true, inset: true },
        {
          avatar: 'https://cdn.vuetifyjs.com/images/lists/4.jpg',
          title: 'Birthday gift',
        },
      ],
        mdiCheckboxBlankCircle, mdiArchive , mdiPlusBox , mdiDatabaseCog, mdiAlertOutline,

        page: 1,
        pageCount: 0,
        itemsPerPage: 5,
        headers: [
          { text: 'Id', value: '_id' },
          { text: 'Status', value: 'commFlag' },
          { text: 'Site', value: 'title' },
          { text: 'Cidade', value: 'city' },
          { text: 'Estado', value: 'state' },
          { text: 'Persistencia(S)', value: 'sampleTime' },
          { text: 'Ultima atualização', value: 'commFlag' },
          { text: 'Desde de ...', value: 'commFlag' },

        ],
        mockalert:[
          {
            id:1,
            status: 'Banana',
            time: new Date().getTime()
          },
          {
            id:2,
            status: 'Jaca',
            time: new Date().getTime()
          },
          {
            id:3,
            status: 'Acerola',
            time: new Date().getTime()
          },
          {
            id:4,
            status: 'Calopsita',
            time: new Date().getTime()
          },
        ]
      }
    },
    methods: {
      ...mapActions(["loadSites"]),
      saber(value) {
      this.$router.push({
        name: ROUTE_NAME.SITE_DASHBOAD,
        params: {
          siteId: value._id,
        },
      });
      }
    },
    async mounted () {
      await this.loadSites()
    },
    computed: {
      ...mapGetters(["sites","alerts"]),
    },
  }
</script>

<style lang="scss" scoped>
.listMenu{
  height: calc(100vh - 64px );
  background-color: #3a567d;
  ul {
    list-style: none;
    padding-left: 0;
    padding-top: 1rem;
  }
  li {
    padding: .8rem;
    font-size: 0.875rem;
    cursor: pointer;
    color: #fff;
    &:hover {
      background: #eeeeee;
      color: #000;
      span {
        padding: 0 5px;
        .v-icon {
          color: #000;
        }
        color: #fff;
      }
    }
      span {
        padding: 0 5px;
        .v-icon {
        color: #fff;
        }
      }
  }
}

.dispositivoTitle {
  background-color: #3a567d;
  color: #fff;
}
.dispositivoTable {
  height: calc(100vh - (64px + 12px + 64px + 12px));
  .tableBody {
    background: #3a567d;
    color: #fff;
    :hover {
      color: #000;
    }
  }
}

.cardAlerta {

  div {
    height: calc(100vh - (64px + 24px));
    .v-card__title {
      background-color: #3a567d !important;
      color: #fff;
    }
  }

}

</style>
